const DOMPurify = require("dompurify");
const $ = require("jquery");
const juice = require("juice");
const PrintWriter = require("./plex-grid-print-writer-base");
const FeatureProcessor = require("../../../Features/plex-feature-processor");
const plexExport = require("../../../../global-export");
const applicationUtils = require("../../../Utilities/plex-utils-application");

const CellPrintWriter = PrintWriter.extend({
  init: function (parent, _options, column) {
    this._base.apply(this, arguments);

    this.column = column;
    this.parent = parent;
    if (column.features && column.features.length > 0) {
      this.featureProcessor = new FeatureProcessor(column.features, column, parent);
    }
  },

  getNodeName: function () {
    return "grid-table-cell";
  },

  prePrint: function (_record, index, _data) {
    const self = this;
    if (self._base.apply(this, arguments)) {
      if (self.column.features && self.column.features.length > 0) {
        self.column.features.forEach((feature) => {
          if (feature.$$resultsCollection && feature.$$resultsCollection[index]) {
            self.features.merge(feature.$$resultsCollection[index]);
          }
        });
      }

      return this.features.render !== false;
    }

    return false;
  },

  print: function (record, index, _data, colIndex, _groupIndex, _group) {
    const self = this;
    let cellXml;

    const css = [].concat(self.features.css);
    if (self.column.css) {
      css.push(self.column.css);
    }

    if (self.column.colspan > 1) {
      self.features.attr.colspan = this.column.colspan;
    }

    self.writeCss(self.node, css);
    self.writeAttributes(self.node, self.features.attr);
    self.writeStyle(self.node, self.features.style);

    if (self.features.content) {
      cellXml =
        self.features.printContent?.length > 1
          ? String(
              self.features.printContent
                .filter((x) => {
                  return x != null;
                })
                .join("\n")
            )
          : String(self.features.content).replace(/<br[^>]*>/gi, "\n");

      if (self._shouldPrintAsHtml()) {
        self._printAsHtml(cellXml);
      } else {
        // strip out any html
        let text = $("<div/>").html(cellXml).text();

        // replace &nbsp; with its XML equivalent
        text = text.replace(/&nbsp;/gi, "&#160;");

        self.node.setValue(text);
      }
    } else {
      cellXml = self.column.valueProvider.getPrintValue(record, index, colIndex, self.features) || "";
      self.node.appendXml(cellXml.replace(/(<plex-controls>|<\/plex-controls>)/g, ""));
    }

    return self.toPrintXml();
  },

  _hasHtmlDisplayFeature: function () {
    return this.featureProcessor.configs.some((feature) => feature.name === "HtmlDisplay");
  },

  _shouldPrintAsHtml: function () {
    return applicationUtils.isVisionPlexRequest() && this._hasHtmlDisplayFeature();
  },

  _printAsHtml: function (cellXml) {
    let processed = cellXml;

    // inline styles
    try {
      processed = juice(processed, { resolveCSSVariables: false });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e.message);
    }

    // hook for cleaning up the html
    DOMPurify.addHook("afterSanitizeElements", cleanTags);
    processed = DOMPurify.sanitize(processed);
    DOMPurify.removeHook("afterSanitizeElements");

    this.node.createNode("plex-control-htmlliteral", { richtext: true }).setValue(processed);
  }
});

function cleanTags(node) {
  // remove empty <tr> as they cause issues during XLST processing.
  if (node.nodeName === "TR") {
    const { childNodes } = node;
    const hasOnlyNonTDElements = Array.from(node.childNodes).every((child) => child.nodeName !== "TD");
    if (childNodes.length === 0 || hasOnlyNonTDElements) {
      node.remove();
      return;
    }
  }

  const bgcolor = node.attributes?.getNamedItem("bgColor");
  const style = node.attributes?.getNamedItem("style");

  // handle bgColor attr
  if (bgcolor) {
    const bgColorAttr = `background-color: ${bgcolor.value}`;
    if (style) {
      const newStyle = style.value.endsWith(";") ? style.value + bgColorAttr : style.value + "; " + bgColorAttr;
      node.setAttribute("style", newStyle);
    } else {
      node.setAttribute("style", bgColorAttr);
    }
    node.removeAttribute("bgColor");
  }
}

module.exports = CellPrintWriter;
plexExport("grid.writers.CellPrintWriter", CellPrintWriter);
