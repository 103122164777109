const $ = require("jquery");
const ko = require("knockout");
const signalR = require("signalR");
const guid = require("../Utilities/plex-utils-guid");
const logger = require("../Core/plex-logger");
const { buildUrl } = require("../Core/plex-navigate");
const plexExport = require("../../global-export");

class ClientNotificationsProvider {
  constructor({ topic = "notifications", clientId, url } = {}) {
    if (!url && !topic) {
      throw new Error("'topic' or 'url' should be defined.");
    }

    const id = clientId || guid.create();
    let connectionUrl = url;
    this.connected = ko.observable(false);

    if (!url) {
      connectionUrl = buildUrl(`/ClientNotifications/${topic}/${id}/subscribe`);
    }

    this._config = {
      topic,
      clientId: id,
      url: connectionUrl
    };

    this._disposing = false;
    this._stopping = false;

    this._connection = new signalR.HubConnectionBuilder()
      .withUrl(this._config.url)
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.None)
      .build();

    this._connection.onreconnected(() => {
      this.connected(true);
    });

    this._connection.onreconnecting(() => {
      this.connected(false);
    });

    this._connection.onclose(() => {
      this.connected(false);
    });
  }

  subscribe(notification, callback) {
    if (!notification) {
      throw new Error("'notification' should be defined.");
    }

    if (!callback) {
      throw new Error("'callback' should be defined.");
    }

    this._connection.on(notification, (response) => {
      logger.debug(`Received '${notification}' from (${this._config.url}).`);
      callback(response);
    });
  }

  unsubscribe(notification) {
    if (!notification) {
      throw new Error("'notification' should be defined.");
    }

    this._connection.off(notification);
  }

  start() {
    const deferred = new $.Deferred();

    if (this._isStopped()) {
      logger.debug(`Starting connection with (${this._config.url}).`);
      this._connection.start().then(
        () => {
          this.connected(true);
          logger.debug(`Established connection with (${this._config.url}).`);
          deferred.resolve(true);
        },
        (err) => {
          this.connected(false);
          deferred.reject(err);
        }
      );
    } else {
      // already connected
      deferred.reject();
    }

    return deferred.promise();
  }

  stop() {
    logger.debug(`Connection with (${this._config.url}) is ${this._disposing ? "disposing" : "stopping"}.`);

    return this._connection.stop().catch((e) => {
      logger.debug(e);
    });
  }

  stream(methodName, ...args) {
    return this._connection.stream(methodName, ...args);
  }

  getClientId() {
    return this._config.clientId;
  }

  dispose() {
    this._disposing = true;
    this.stop();
  }

  _isStopped() {
    return this._connection.state === signalR.HubConnectionState.Disconnected;
  }
}

module.exports = ClientNotificationsProvider;
plexExport("clientNotifications.ClientNotificationsProvider", ClientNotificationsProvider);
