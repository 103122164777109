ï»¿const $ = require("jquery");
const DOMPurify = require("dompurify");
const plexExport = require("../../global-export");
const environment = require("../Core/plex-env");

const titleCaseRgx = /([a-z])([A-Z])/g;
const separatorRgx = /([_ ]+)([a-z])/gi;
const numericRgx = /^[-]?\d*\.?\d*$/g;
const lineBreakRgx = /<br[^>]*>/gi;
const slice = Array.prototype.slice;
const FEATURE_FLAG = "feat-vp-3187-html-injection-sanitize-toggle";

function indexOf(source, pattern, caseSensitive, startIndex = 0) {
  // check for null/undefined but allow 0
  if (source == null || source === "" || pattern == null || pattern === "") {
    return -1;
  }

  let sourceString = String(source);
  let patternString = String(pattern);

  if (patternString.length > sourceString.length) {
    return -1;
  }

  if (!caseSensitive) {
    sourceString = sourceString.toLowerCase();
    patternString = patternString.toLowerCase();
  }

  return sourceString.indexOf(patternString, startIndex);
}

const api = {
  toTitleCase: function (text) {
    /// <summary>Converts a string from camelcase to titlecase with spaces between detected breaks.</summary>
    /// <param name="text">The text to convert.</param>
    /// <returns type="String">The converted text.</returns>

    if (text && text.length > 1) {
      return (
        text.charAt(0).toUpperCase() +
        text
          .substr(1)
          .replace(titleCaseRgx, "$1 $2")
          .replace(separatorRgx, ($0, $1, $2) => {
            return " " + $2.toUpperCase();
          })
      );
    }

    return text;
  },

  capitalize: function (text) {
    /// <summary>Capitalized the provided string.</summary>
    /// <param name="text">The text to capitalize.</param>
    /// <returns type="String">The capitalized string.</returns>

    if (!text) {
      return text;
    }

    if (text.length === 1) {
      return text.toUpperCase();
    }

    return text.charAt(0).toUpperCase() + text.substr(1);
  },

  /**
   * Determines whether a string starts with the pattern text.
   *
   * @param {string} source - the string to search
   * @param {string} pattern - the pattern to find
   * @param {boolean} [caseSensitive=false] - determins whether search is case sensitive
   * @returns {boolean}
   */
  startsWith: function (source, pattern, caseSensitive) {
    return indexOf(source, pattern, caseSensitive) === 0;
  },

  /**
   * Determines whether a string ends with the pattern text.
   *
   * @param {string} source - the string to search
   * @param {string} pattern - the pattern to find
   * @param {boolean} [caseSensitive=false] - determins whether search is case sensitive
   * @returns {boolean}
   */
  endsWith: function (source, pattern, caseSensitive) {
    const sourceString = String(source ?? "");
    const patternString = String(pattern ?? "");
    const expectedPosition = sourceString.length - patternString.length;

    const index = indexOf(sourceString, patternString, caseSensitive, expectedPosition);
    if (index === -1) {
      return false;
    }

    return index === expectedPosition;
  },

  contains: function (source, pattern, caseSensitive) {
    /// <summary>Determines whether a string contains the matching text.</summary>
    /// <param name="source">The string to search through.</param>
    /// <param name="pattern">The string to match with.</param>
    /// <param name="caseSensitive">Indicates whether the match is case sensitive or not. OPTIONAL - (Default is false)</param>
    /// <returns type="Boolean">True if matches; false otherwise.</returns>

    return indexOf(source, pattern, caseSensitive) >= 0;
  },

  format: function (text /* , args */) {
    /// <summary>
    /// Formats a string, replacing tokens from within the string with the arguments supplied.
    /// Note that this function auto-detects whether the string is using a 1-based or 0-based
    /// sequence based on the tokens contained within the string.
    /// </summary>
    /// <param name="text">The text to be updated.</param>
    /// <param name="args">You can use a single array of tokens or pass in a variable number of arguments</param>
    /// <returns type="String">The updated string.</returns>

    if (!text || arguments.length < 2) {
      return text;
    }

    let tokens, i, rgx;
    if (arguments.length === 2 && Array.isArray(arguments[1])) {
      // be forgiving if the dev passes in an array of tokens
      // instead of using multiple arguments
      // that might even be more convient sometimes
      tokens = arguments[1];
    } else {
      tokens = slice.call(arguments, 1);
    }

    // figure out if the string is 1 based or 0 based
    const offset = /\{0\}/.test(text) ? 0 : 1;

    i = tokens.length;
    while (i--) {
      rgx = new RegExp("\\{" + (i + offset) + "\\}", "g");
      text = text.replace(rgx, tokens[i] == null ? "" : tokens[i]);
    }

    return text;
  },

  replaceHtmlLineBreaks: function (text, replaceWith) {
    /// <summary>Replace html line breaks with new line in the provided text.</summary>
    /// <param name="text">The text to replace html line breaks from.</param>
    /// <param name="replaceWith">The text to replace line breaks with. (default value is "\n")</param>
    /// <returns type="String">The string with line breaks replaced with new lines.</returns>

    replaceWith = replaceWith || "\n";
    if (typeof text === "string") {
      return text.replace(lineBreakRgx, replaceWith);
    }

    return text;
  },

  removeHtml: function (text, options) {
    /// <summary>Strips out html from the provided text.</summary>
    /// <param name="text">The text to remove html from.</param>
    /// <param name="options">
    /// An options object to apply against the provided object.
    /// - replaceLineBreaks: true if to replace line break tags with new line before stripping out html. (default false)
    /// </param>
    /// <returns type="String">The string with html stripped out.</returns>

    if (typeof text !== "string") {
      return text;
    }

    options = options || {};
    if (options.replaceLineBreaks) {
      text = this.replaceHtmlLineBreaks(text);
    }

    return $("<div/>").html(text).text();
  },

  escapeHtml: function (text, options) {
    /// <summary>Escapes the provided text for use within HTML.</summary>
    /// <param name="text">The text to escape.</param>
    /// <param name="options">
    /// An options object to apply against the provided object.
    /// - ignoreLineBreaks: true if not to escape line break tags. (default false)
    /// </param>
    /// <returns type="String">The escaped string.</returns>

    if (typeof text !== "string") {
      return text;
    }

    options = options || {};
    if (options.ignoreLineBreaks) {
      text = text.replace(lineBreakRgx, "\n");
    }

    text = text
      .replace(/&/g, "&amp;")
      .replace(/>/g, "&gt;")
      .replace(/</g, "&lt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&apos;");

    if (options.ignoreLineBreaks) {
      text = text.replace(/\n/g, "<br />");
    }

    return text;
  },

  unescapeHtml: function (text) {
    /// <summary>Unescapes the provided text for use within HTML.</summary>
    /// <param name="text">The text to unescape.</param>
    /// <returns type="String">The unescaped string.</returns>

    if (typeof text !== "string") {
      return text;
    }

    const dirty = text
      .replace(/&amp;/g, "&")
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
      .replace(/&quot;/g, '"')
      .replace(/&apos;/g, "'");

    return environment.features[FEATURE_FLAG]
      ? DOMPurify.sanitize(dirty, { ADD_ATTR: ["target"], FORCE_BODY: true })
      : dirty;
  },

  repeat: function (count, text) {
    /// <summary>Repeats the provided string the number of times specified.</summary>
    /// <param name="count">The number of times to repeat the string.</param>
    /// <param name="text">The string to repeat.</param>
    /// <returns type="String">The repeated text.</returns>

    return Array(count + 1).join(text);
  },

  isNumeric: function (text) {
    /// <summary>Determines if the string is numeric.</summary>
    /// <param name="text">The string.</param>
    /// <returns type="Boolean">True, if the string is numeric; otherwise false.</returns>

    if (!text) {
      return false;
    }

    return text.toString().match(numericRgx);
  },

  isString: function (value) {
    /// <summary>Determines if the value is string type.</summary>
    /// <param name="value">The value.</param>
    /// <returns type="Boolean">True, if the value is string; otherwise false.</returns>

    return typeof value === "string" || value instanceof String;
  },

  /**
   * Determines what the length of the string value would be as a VARCHAR
   * stored in a NScale SQL server.
   *
   * @param {String} value - the string to evaluate
   * @returns {Number} the length the string would be as an NScale VARCHAR
   */
  byteLength: function (value) {
    if (value == null) {
      return 0;
    }

    value = String(value);

    let len = value.length;

    // based on https://stackoverflow.com/a/23329386
    for (let i = value.length - 1; i >= 0; i--) {
      const code = value.charCodeAt(i);
      if (code > 0x7f && code <= 0x7ff) {
        len++;
      } else if (code > 0x7ff && code <= 0xffff) {
        len += 2;
      }

      if (code >= 0xdc00 && code <= 0xdfff) {
        // trail surrogate
        i--;
      }
    }

    return len;
  }
};

module.exports = api;
plexExport("strings", api);
