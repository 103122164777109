ï»¿/* eslint-disable no-invalid-this */
const ResizeObserver = require("ResizeObserver");
const $ = require("jquery");
const jsUtils = require("../../Utilities/plex-utils-js");
const plexImport = require("../../../global-import");
const plexExport = require("../../../global-export");
const pubsub = require("../../Core/plex-pubsub");
const stringUtils = require("../../Utilities/plex-utils-strings");

require("./plex-sidetabs-menu-page-header"); // eslint-disable-line import/no-unassigned-import
require("../../Plugins/plex-fixedelement"); // eslint-disable-line import/no-unassigned-import

const PLEX_FORM_FOOTER_SELECTOR = ".plex-form-footer";

function SidetabsMenuPage(element, config) {
  this.init(element, config);
}

SidetabsMenuPage.prototype = {
  constructor: SidetabsMenuPage,

  init: function (el, config) {
    const self = this;

    this.$element = $(el);
    this.config = config || {};

    $("body>div").children("style").parent().hide();

    this.$pageInlineStyle = $('<style type="text/css"></style> ');
    this.$pageInlineStyle.appendTo("head");

    this.$pageHeader = $(".plex-sidetabs-menu-page-content-header");
    this.$pageHeader.sidetabsMenuPageHeader({ id: "sidetabsMenuPageHeader", enableBackNavigation: true });

    this.$pageContentContainer = $(".plex-sidetabs-menu-page-content-container");
    this.pageContentContainerBorderSize = this._getFourDimensionedCssProperty(
      this.$pageContentContainer,
      "border-{0}-width"
    );

    this.pageContentContainerBorderHeight =
      this.pageContentContainerBorderSize.top + this.pageContentContainerBorderSize.bottom + 1;

    this.$absolutelyPositionedContent = this.$pageContentContainer
      .find(".plex-wizard, .plex-dashboard, form.plex-form")
      .first();
    this.$formContainerWrapper = this.$absolutelyPositionedContent.find("> .plex-form-content-wrapper");

    this.resizableBodyTarget = $("body").get(0);

    this.resizeObserver = new ResizeObserver((entries) => {
      if (entries.filter((e) => e.target === self.resizableBodyTarget)[0]) {
        self.resize();
      } else if (entries.filter((e) => $(e.target).hasClass("plex-form-content"))[0]) {
        self._onPageContainerContentHeightChanged();
      }
    });

    this.resizeObserver.observe(this.resizableBodyTarget);
    this.$absolutelyPositionedContent.find(".plex-form-content").each((index, element) => {
      self.resizeObserver.observe(element);
    });

    pubsub.subscribe("sidetabsMenuLoaded", () => {
      self.resize();
    });

    pubsub.subscribe("helpPanelResized", () => {
      self._onPageContainerContentWidthChanged();
      self._updateDynamicStyles();
    });

    pubsub.subscribe("chatPanelResized", () => {
      self._onPageContainerContentWidthChanged();
      self._updateDynamicStyles();
    });

    pubsub.subscribe("formSectionsMenuLoaded", () => {
      // to change color schema of left menu
      self.$element.addClass("has-form-sections");
    });

    pubsub.subscribe("sidetabsMenuToggle", () => {
      self._onPageContainerContentWidthChanged();

      self._updateDynamicStyles();
    });

    pubsub.subscribe("selectedSectionRendered", () => {
      // handle resizing of grids
      self._onPageContainerContentWidthChanged();

      // handle adjusting container height to its content
      self._onPageContainerContentHeightChanged();

      // update style definition to handle sticky element in IE
      self._updateDynamicStyles();
    });

    pubsub.subscribe("wizardContentLoadingStarted", () => {
      // lock actions bar separate because it has fixed position
      $(PLEX_FORM_FOOTER_SELECTOR).block({ overlayCss: { "background-color": "transparent" }, message: " " });
    });

    pubsub.subscribe("wizardContentLoadingCompleted", () => {
      $(PLEX_FORM_FOOTER_SELECTOR).unblock();
      self._updateDynamicStyles();
    });

    $("body").on("columnResized", (e, data) => {
      if (data && data.grid) {
        const grid = data.grid;

        grid.resize();

        self._onGridFixed();
      }
    });

    // fix element to handle horizontal scrolling of grid
    $("body").on("fixed.plex", (e) => {
      if (e.target) {
        self._onElementFixed(e.target);
      }
    });

    $(window).on("resize", () => {
      self.resize();
    });

    const messageBannerContainerHeight = $(".plex-persistent-banner-container").outerHeight() ?? 0;
    const environmentBannerContainerHeight = $(".plex-env-persistent-banner-container").outerHeight() ?? 0;
    $(":root").css(
      "--side-menu-persistent-banner-height",
      `${messageBannerContainerHeight + environmentBannerContainerHeight}px`
    );
  },

  // resize containers size when window is resizing, menu collapse/expand, change active content
  resize: jsUtils.throttleRender(function () {
    this._onPageContainerContentWidthChanged();

    this._recalculatePageContainersHeight();

    this.$pageContentContainer.scrollLeft(0);
  }),

  _recalculatePageContainersHeight: function () {
    const $rootElement = this.$element;

    // calculate height of elements before and after container
    const rootElementSiblingsHeight = this._getElementSiblingsHeight(
      $rootElement,
      ".plex-waiting-message, .modal-backdrop, .plex-dialog, .apt-guide-overlay-top, .apt-guide-overlay-bottom, .apt-guide-overlay-left, .apt-guide-overlay-right, .toast-top-full-width, .html2canvas-container, [data-testid='modal-dialog']",
      (element) => {
        return $(element).css("cursor") !== "wait" && $(element).children("style").length === 0;
      }
    );

    // separate get height of bottom action bar because of it has fixed position
    const actionsContainerHeight = $(
      `${PLEX_FORM_FOOTER_SELECTOR}:visible, .plex-calendar-buttons:visible, .plex-grid-buttons:visible`
    ).outerHeight();
    const rootElementScrollWidth = Math.abs($rootElement.width() - $rootElement.get(0).scrollWidth >= 1)
      ? $.getScrollBarSize().width
      : 0;

    // set calculated height for root container
    $rootElement.css(
      "height",
      `${window.innerHeight - (rootElementSiblingsHeight + (actionsContainerHeight || 0) + rootElementScrollWidth)}`
    );

    // to make horizontal scrollbar visible without needs vertical scrolling there is need set up max-height of element. For it there is just needed subtract header height from root container height
    const containerHeight =
      $rootElement.outerHeight() - (this.$pageHeader.outerHeight() || 0) - this._getContainerBottomOffset();

    // set max height to adjust container by content
    this.$pageContentContainer.css({ maxHeight: containerHeight });

    this._onPageContainerContentHeightChanged();

    this._updateDynamicStyles();

    pubsub.publish("sideTabsMenuPageContainerContentHeightChanged");
  },

  // get height of all container's child visible elements
  _getElementSiblingsHeight: function (element, excludedSelectors, processingElementsFilter) {
    const $parent = element.parent();
    let result = $parent.children().not(element);

    if (excludedSelectors) {
      // ignore some elements
      result = result.not(excludedSelectors);
    }

    return result
      .filter(":visible")
      .filter(function () {
        return processingElementsFilter ? processingElementsFilter(this) : true;
      })
      .get()
      .reduce((total, el) => {
        const $el = $(el);

        const sum = total + $el.outerHeight();

        // ignore absolute positioned elements if they do not change size parent element
        if ($el.css("position") === "absolute") {
          if ($parent.css("position") === "relative") {
            return sum;
          } else {
            return total;
          }
        } else {
          return sum;
        }
      }, 0);
  },

  _onPageContainerContentWidthChanged: jsUtils.throttleRender(function () {
    const currentPage = plexImport("currentPage");

    Object.keys(currentPage).forEach((e) => {
      const controller = currentPage[e];

      if (typeof controller.resize === "function") {
        controller.resize();
      }
    });

    this.$pageContentContainer.scrollLeft(0);

    pubsub.publish("sideTabsMenuPageContainerContentWidthChanged");
  }),

  _onPageContainerContentHeightChanged: jsUtils.throttleRender(function () {
    if (this.$absolutelyPositionedContent.length) {
      const activeContentHeight = this.$formContainerWrapper
        .children()
        .get()
        .reduce((total, el) => {
          const $el = $(el);

          return total + $el.outerHeight();
        }, 0);

      if (activeContentHeight > 0) {
        this.$pageContentContainer.css({
          height: activeContentHeight + parseInt(this.$absolutelyPositionedContent.css("top"), 10) + 20
        });
      } else {
        this.$pageContentContainer.css({ height: this.$pageContentContainer.css("maxHeight") });
      }
    } else {
      this.$pageContentContainer.css({ height: "100%" });
    }
  }),

  _updateDynamicStyles: function () {
    const containerOffset = this.$pageContentContainer.offset();
    const leftOffset = containerOffset.left + this.pageContentContainerBorderSize.left;
    const rightOffset =
      window.innerWidth - this.$pageContentContainer.get(0).getBoundingClientRect().right + $.getScrollBarSize().width;

    let topOffset = containerOffset.top + this.pageContentContainerBorderSize.top;

    const fixedActionbarStyle = `.plex-sidetabs-menu-page-content-container .plex-actions-fixed {position: fixed!important; top: ${topOffset}px!important; left: ${leftOffset}px!important; right: ${rightOffset}px; width: auto;}`;

    const $actionbar = $(".plex-grid-content")
      .parents(".plex-sidetabs-menu-page-content-container")
      .find(".plex-actions-wrapper")
      .find("ul");

    if ($actionbar.length) {
      topOffset += $actionbar.outerHeight();
    }

    const fixedGridHeaderStyle = `.plex-sidetabs-menu-page-content-container .plex-grid-header-fixed {top: ${topOffset}px!important; right: ${rightOffset}px;}`;

    // When resizing the footer buttons, use the width of ".plex-sidetabs-menu-page-content" instead of "this.$pageContentContainer" used above. This
    // is because certain screens that use a left side menu (example, Part Detail) have the main content wrapped by a 25 pixel margin. This margin
    // isn't included in the getBoundingClientRect() calculation, and as a result, causes the footer bar to come up slightly short on these screens.
    // If ".plex-sidetabs-menu-page-content" is unavailable, "this.$pageContentContainer" will be used as a fallback.
    const footerButtonRight =
      window.innerWidth -
      ($(".plex-sidetabs-menu-page-content").get(0) || this.$pageContentContainer.get(0)).getBoundingClientRect().right;

    const fixedGridButtonsStyle = `.plex-grid-buttons {right: ${footerButtonRight}px}`;
    const fixedFormFooterStyle = `.plex-sidetabs-menu-page-content .plex-form-footer, .plex-sidetabs-menu-page-content .plex-calendar-buttons {right: ${footerButtonRight}px}`;

    this.$pageInlineStyle.html(
      fixedActionbarStyle + fixedGridHeaderStyle + fixedGridButtonsStyle + fixedFormFooterStyle
    );

    this._onGridFixed();
  },

  _onElementFixed: function (element) {
    if (element) {
      const $element = $(element);

      if ($element.parents(".plex-sidetabs-menu-page-content-container").length) {
        if ($element.hasClass("plex-grid-header-fixed")) {
          this._onGridFixed();
        }
      }
    }
  },

  _onGridFixed: function () {
    const $fixedGrid = this.$pageContentContainer.find(".plex-grid-header-fixed");

    if ($fixedGrid.length) {
      const $gridContainer = $fixedGrid.parents(".plex-grid-container");
      if ($gridContainer.length) {
        $fixedGrid.css({
          position: "fixed"
        });

        const gridContainerOffset = $gridContainer.offset();
        const rightOffset =
          window.innerWidth -
          this.$pageContentContainer.get(0).getBoundingClientRect().right +
          $.getScrollBarSize().width;

        const $scrollParent = $gridContainer.scrollParent();

        const leftOffset = gridContainerOffset.left + $scrollParent.scrollLeft();

        $fixedGrid.css({
          left: leftOffset,
          overflow: "hidden"
        });
        $fixedGrid.find("table").css({
          "margin-left": `-${$scrollParent.scrollLeft()}px`,
          "margin-right": `${$scrollParent.scrollLeft()}px`
        });

        const $formWrapper = $gridContainer.parents(".plex-form-content-wrapper");

        if ($formWrapper.length) {
          if ($formWrapper.get(0).scrollWidth > $formWrapper.outerWidth()) {
            $fixedGrid.css({ right: `${rightOffset}px` });
          } else {
            $fixedGrid.css({ right: `${rightOffset + 35}px` });
          }
        }
      }
    }
  },

  _getNumericCssProperty: function (elementObject, propertyName, isFloat) {
    const propertyValue = elementObject.css(propertyName);

    if (isFloat) {
      return parseFloat(propertyValue);
    } else {
      return parseInt(propertyValue, 10);
    }
  },

  _getFourDimensionedCssProperty: function (elementObject, propertyNameFormat) {
    return {
      top: this._getNumericCssProperty(elementObject, stringUtils.format(propertyNameFormat, "top")),
      left: this._getNumericCssProperty(elementObject, stringUtils.format(propertyNameFormat, "left")),
      right: this._getNumericCssProperty(elementObject, stringUtils.format(propertyNameFormat, "right")),
      bottom: this._getNumericCssProperty(elementObject, stringUtils.format(propertyNameFormat, "bottom"))
    };
  },

  _getContainerBottomOffset: function () {
    return $(".with-sidetabs-menu").length ? 10 : 0;
  }
};

$.fn.sidetabsMenuPage = function (options) {
  const settings = $.extend({}, options);

  return this.each(function () {
    const sidetabsMenuPage = $(this).data("sidetabsMenuPage");

    if (!sidetabsMenuPage) {
      $(this).data("sidetabsMenuPage", new SidetabsMenuPage(this, settings));
    }

    return this;
  });
};

module.exports = SidetabsMenuPage;
plexExport("sidetabsMenuPage", SidetabsMenuPage);
