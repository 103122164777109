const plexExport = require("../../global-export");
const plexImport = require("../../global-import");

const api = {
  isVisionPlexRequest: function () {
    const currentApplication = plexImport("currentApplication");
    const area = currentApplication.AreaName?.toLowerCase();
    const controller = currentApplication.ControllerName?.toLowerCase();

    return area === "visionplex" && controller === "screen";
  }
};

module.exports = api;
plexExport("application", api);
